import { useContactStore } from '~/store/contact'
import { IContact } from '~~/types/seo'

export const useFooterNavigation = () => {
  const { $config, $pinia } = useNuxtApp()
  const store = useContactStore($pinia)

  const cskhUrl = computed(() => store.contact?.find((item: IContact) => item.name === 'cskh')?.url)
  const livechatUrl = computed(() => store.contact?.find((item: IContact) => item.name === 'livechat')?.url)
  const email = computed(() => store.contact?.find((item: IContact) => item.name === 'livechat')?.content)
  const congdongUrl = computed(() => store.contact?.find((item: IContact) => item.name === 'congdong')?.url)

  return {
    cskhUrl,
    livechatUrl,
    congdongUrl,
    email
  }
}
