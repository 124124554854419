<template>
  <div v-if="contact?.length ?? 0 > 0" class="floating">
    <div class="floating-background ">
      <div v-if="contact && contact[0] && contact[0].url !== ''" class="floating__item ">
        <NuxtLink :to="contact[0].url" :target="contact[0]?.name === 'khuyenmai' ? null : '_blank'" class="floating__item--image animation">
          <BaseImg v-if="!isBotChromeLighthouse" class="icon icon-bottom" :src="contact[0].img" alt="icon" />
        </NuxtLink>
        <div class="tooltip">
          <div class="tooltip-content">
            <span class="description">{{ contact[0]?.display_name }}</span>
          </div>
          <div class="arrow-down" />
        </div>
      </div>
    </div>
    <div class="floating-background">
      <div
        v-for="(item, indexSubMenu) in filteredContacts"
        :key="item.id || indexSubMenu"
        class="floating__item other"
      >
        <NuxtLink :to="item.url" :target="item?.name === 'khuyenmai' ? null : '_blank'" class="floating__item--image">
          <BaseImg v-if="!isBotChromeLighthouse" class="icon icon-bottom" :src="item.img" alt="icon" />
          <div class="tooltip">
            <div class="tooltip-content">
              <span class="description">{{ item?.display_name }}</span>
            </div>
            <div class="arrow-down" />
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
import { useContactStore } from '~/store/contact'
const { isBotChromeLighthouse } = useAppStore()
const { contact, fetchContact } = useContactStore()
useAsyncData(() => fetchContact())

const filteredContacts = computed(() => {
  return contact?.slice(1).filter((item) => item?.url) ?? []
})
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/floating-menu/index.scss" />
