<template>
  <div class="header-user">
    <client-only>
      <div class="user-block">
        <div class="user-info">
          <div class="balance">{{ $formatNumberWithCommas(store.currentUser?.balance, ',') }} K</div>
          <div class="transaction">
            <div class="transaction__deposit" :to="ACCOUNT_URLS.DEPOSIT_DA" @click="onNavDeposit()">
              <BaseImg
                src="/assets/images/components/desktop/header/icon-deposit.svg"
                class="transaction__icon"
                alt="deposit"
              />
              <span>Nạp tiền</span>
            </div>
            <div class="transaction__withdraw" @click="onNavWithdraw()">Rút tiền</div>
          </div>
        </div>
        <div class="user-menu" @mouseleave="mouseLeave" @mouseover="mouseOver">
          <div class="group-avatar">
            <div class="avatar" :class="{ show: isShowMenu }">
              <BaseImg
                :src="`/assets/images/components/desktop/avatar/${store.currentUser?.avatar}.webp`"
                alt="avatar"
              />
            </div>
            <BaseImg
              src="/assets/images/components/desktop/header/ic-hamburger.svg"
              alt="icon-hamburger"
              class="icon-hamburger"
            />
          </div>
          <div class="menu-dropdown" :class="{ show: isShowMenu }">
            <div
              v-for="(item, index) in USER_MENU"
              :id="item?.name"
              :key="index"
              class="menu-item"
              @click="onClick(item)"
            >
              <span :class="item.icon" class="icon" />
              <div class="text">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import { useLogout } from '~/composables/user/useLogout'
import { USER_MENU } from '~/constants/menu'
import { useAppStore } from '~/store/app'
import { ACCOUNT_URLS } from '~/config/page-url'
import { IMenuUserDesktop } from '~/types/menu.type'
const { $pinia, $formatNumberWithCommas } = useNuxtApp()
const store = useAppStore($pinia)
const { logout } = useLogout()
const isShowMenu = ref(false)

const truncatedFullName = computed(() => {
  const fullname = store.currentUser?.fullname || ''
  return fullname.length > 15 ? fullname.substring(0, 12) + '...' : fullname
})
const onClick = (item: IMenuUserDesktop) => {
  isShowMenu.value = false
  if (item.url === ACCOUNT_URLS.DEPOSIT_DA) {
    onNavDeposit()
    return
  }
  if (item.url === ACCOUNT_URLS.WITHDRAW_BANK) {
    onNavWithdraw()
    return
  }
  if (item.isLogout) {
    logout()
  } else {
    useRoute().path !== item.url && navigateTo(item.url)
  }
}

const mouseOver = () => {
  isShowMenu.value = true
}

const mouseLeave = () => {
  isShowMenu.value = false
}
const onNavDeposit = () => {
  if (useRoute().path !== ACCOUNT_URLS.DEPOSIT_DA) {
    navigateTo(ACCOUNT_URLS.DEPOSIT_DA)
  }
}
const onNavWithdraw = () => {
  if (useRoute().path === ACCOUNT_URLS.WITHDRAW_BANK) {
    return
  }
  navigateTo(ACCOUNT_URLS.WITHDRAW_BANK)
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/header/user-logged.scss" />
